import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { AuthService } from './services/authService';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InMemoryCache } from '@apollo/client/core';

import { GoogleMaps } from './services/googleMaps';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { API_URL, BUGSNAG_API_KEY } from 'src/environments/environment';
import { AuthTokenInterceptorService } from './interceptors/AuthTokenInterceptorService';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { HttpLink } from 'apollo-angular/http';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { NgxViewerModule } from 'ngx-viewer';


@NgModule({
  declarations: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      scrollAssist: false
    }),
    IonicStorageModule.forRoot(
      {
        name: 'statoutdoorsdb',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }
    ),
    AppRoutingModule,
    NgxViewerModule,
    HttpClientModule,
    ApolloModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(
            {
              typePolicies: {
                Spot: {
                  fields: {
                    mapFeatures: {
                      merge(existing, incoming) {
                        return incoming
                      }
                    }
                  }
                },
                Query: {
                  fields: {
                    publicSocialFeed: offsetLimitPagination(),
                  }
                },
              }
            }
          ),
          link: httpLink.create({
            uri: API_URL + '/graphql'
          }),
        };
      },
      deps: [HttpLink],
    },
    AuthService,
    GoogleMaps,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptorService, multi: true },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
