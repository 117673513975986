import { PointModel } from '../models/pointModel';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from "@angular/core";

declare var google: any;
@Injectable()
export class GoogleMaps {

    constructor() { }

    public createSpot(center, dragHandler) {
        const divMap = (document.getElementById('map') as HTMLInputElement);
        const map = new google.maps.Map(divMap, {
            center,
            zoom: 15,
            draggable: true,
            streetViewControl: false,
            zoomControl: true
        });
        const myMarker = new google.maps.Marker({
            position: center,
            draggable: true,
            map
        });


        google.maps.event.addListener(myMarker, 'dragend', function () {
            map.setCenter(this.getPosition()); // Set map center to marker position
            updatePosition(this.getPosition().lat(), this.getPosition().lng()); // update position display
        });

        google.maps.event.addListener(map, 'drag', function () {
            myMarker.setPosition(this.getCenter()); // set marker position to map center
            updatePosition(this.getCenter().lat(), this.getCenter().lng()); // update position display
        });

        google.maps.event.addListener(map, 'dragend', function () {
            myMarker.setPosition(this.getCenter()); // set marker position to map center
            updatePosition(this.getCenter().lat(), this.getCenter().lng()); // update position display
        });

        function updatePosition(lat, lng) {
            document.getElementById('dragStatus').innerText = lat.toFixed(4) + ' , ' + lng.toFixed(4);
            const point = new PointModel();
            point.lat = (lat);
            point.long = (lng);
            dragHandler(null, point);
        }
    }

    public spotMap(center, elementId) {
            const divMap = (document.getElementById(elementId) as HTMLInputElement);
            const map = new google.maps.Map(divMap, {
                center,
                zoom: 17,
                draggable: true,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: true,
                rotateControl: false,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                  },
                mapTypeId: 'satellite'

            });
            return map
    }

}
