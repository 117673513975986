export const environment = {
  production: true,
  development: false,
  GA_TRACKER_ID: 'UA-140814636-3',
};

export const API_URL = 'https://api.beta.statoutdoors.com';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBzsz36voRor52FpzEvUL00Bg8mogKBihc';
export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51J7tiqGWJyYGy2XwXg9tO5yiti2KaPDuJ9nZuAIsS1FGkdFTcRW12jc7VBaTDeVxAE0XOyulBRWv46Lb43j7OtFV00Z5paCTeN'
export const BUGSNAG_API_KEY = 'af9a98d79efb57163e64386ea3641864'
export const GLASSFY_API_KEY = '605ea6cf33dd44eb8ea51c3e0822a312'

