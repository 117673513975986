export class PointModel {

	private _id: any;
	private _lat: any;
	private _long: any;

	public fillModel(rawPoint) {
		this._id = rawPoint.id
		this._lat = rawPoint.lat;
		this._long = rawPoint.long;
	}


	/**
	 * Getter id
	 * @return {any}
	 */
	public get id(): any {
		return this._id;
	}

	/**
	 * Getter lat
	 * @return {any}
	 */
	public get lat(): any {
		return this._lat;
	}

	/**
	 * Getter long
	 * @return {any}
	 */
	public get long(): any {
		return this._long;
	}

	/**
	 * Setter id
	 * @param {any} value
	 */
	public set id(value: any) {
		this._id = value;
	}

	/**
	 * Setter lat
	 * @param {any} value
	 */
	public set lat(value: any) {
		this._lat = value;
	}

	/**
	 * Setter long
	 * @param {any} value
	 */
	public set long(value: any) {
		this._long = value;
	}


}