export const badges = [
    {
        ID: 1,
        NAME: "1st Trip",
        DESCRIPTION: "Log a trip",
        EXPGIVEN: 50,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/first_trip.png'
    },
    {
        ID: 2,
        NAME: "1st Kill",
        DESCRIPTION: "Log a kill",
        EXPGIVEN: 50,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/first_kill.png'
    },
    {
        ID: 3,
        NAME: "1st Trophy",
        DESCRIPTION: "Log a trophy",
        EXPGIVEN: 50,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/first_trophy.png'
    },
    {
        ID: 4,
        NAME: "1st Catch",
        DESCRIPTION: "Log a catch",
        EXPGIVEN: 50,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/first_catch.png'
    },
    {
        ID: 5,
        NAME: "50 Career Hunts",
        DESCRIPTION: "Log 50 hunts in your career",
        EXPGIVEN: 500,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/hunting_trip_50.png'
    },
    {
        ID: 6,
        NAME: "100 Career Hunts",
        DESCRIPTION: "Log 100 hunts in your career",
        EXPGIVEN: 1000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/hunting_trip_100.png'
    },
    {
        ID: 7,
        NAME: "200 Career Hunts",
        DESCRIPTION: "Log 200 hunts in your career",
        EXPGIVEN: 2000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/hunting_trip_200.png'
    },
    {
        ID: 8,
        NAME: "500 Career Hunts",
        DESCRIPTION: "Log 500 hunts in your career",
        EXPGIVEN: 5000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/hunting_trip_500.png'
    },
    {
        ID: 9,
        NAME: "1000 Career Hunts",
        DESCRIPTION: "Log 1000 hunts in your career",
        EXPGIVEN: 10000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/hunting_trip_1000.png'
    },
    {
        ID: 10,
        NAME: "50 Career Fishing Trips",
        DESCRIPTION: "Log 50 fishing trips in your career",
        EXPGIVEN: 500,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/fishing_trip_50.png'
    },
    {
        ID: 11,
        NAME: "100 Career Fishing Trips",
        DESCRIPTION: "Log 100 fishing trips in your career",
        EXPGIVEN: 1000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/fishing_trip_100.png'
    },
    {
        ID: 12,
        NAME: "200 Career Fishing Trips",
        DESCRIPTION: "Log 200 fishing trips in your career",
        EXPGIVEN: 2000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/fishing_trip_200.png'
    },
    {
        ID: 13,
        NAME: "500 Career Fishing Trips",
        DESCRIPTION: "Log 500 fishing trips in your career",
        EXPGIVEN: 5000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/fishing_trip_500.png'
    },
    {
        ID: 14,
        NAME: "1000 Career Fishing Trips",
        DESCRIPTION: "Log 1000 fishing trips in your career",
        EXPGIVEN: 10000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/fishing_trip_1000.png'
    },
    {
        ID: 15,
        NAME: "25 Season Trips",
        DESCRIPTION: "Log 25 trips in a season",
        EXPGIVEN: 500,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/season_trip_25.png'
    },
    {
        ID: 16,
        NAME: "60 Season Trips",
        DESCRIPTION: "Log 50 trips in a season",
        EXPGIVEN: 1000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/season_trip_60.png'
    },
    {
        ID: 17,
        NAME: "100 Season Trips",
        DESCRIPTION: "Log 100 trips in a season",
        EXPGIVEN: 2000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/season_trip_100.png'
    },
    {
        ID: 18,
        NAME: "Not From Around Here",
        DESCRIPTION: "Kill or catch animal or fish out of state",
        EXPGIVEN: 200,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/not_from_around_here.png'
    },
    {
        ID: 19,
        NAME: "Once in a Lifetime",
        DESCRIPTION: "Register a 180 inch or larger deer",
        EXPGIVEN: 1000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/once_in_a_lifetime.png'
    },
    {
        ID: 20,
        NAME: "Lunker",
        DESCRIPTION: "Register a 50lb or larger fish",
        EXPGIVEN: 500,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/lunker.png'
    },
    {
        ID: 21,
        NAME: "Tall Tale",
        DESCRIPTION: "Register a 100lb or larger fish",
        EXPGIVEN: 1000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/tall_tale.png'
    },
    {
        ID: 22,
        NAME: "Bonafide",
        DESCRIPTION: "25 big game kills",
        EXPGIVEN: 2000,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/bonafide.png'
    },
    {
        ID: 23,
        NAME: "Deadeye",
        DESCRIPTION: "60% or better shooting percentage through the season (more than 100 shots)",
        EXPGIVEN: 500,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/deadeye.png'
    },
    {
        ID: 24,
        NAME: "Shooting Blanks",
        DESCRIPTION: "15% or worse shooting percentage through the season (more than 100 shots)",
        EXPGIVEN: 50,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/shooting_blanks.png'
    },
    {
        ID: 25,
        NAME: "Deer Master Badge",
        DESCRIPTION: "25 deer killed",
        EXPGIVEN: 5000,
        MASTER: true,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/deer_master_raw.png'
    },
    {
        ID: 26,
        NAME: "Turkey Master Badge",
        DESCRIPTION: "20 turkey killed",
        EXPGIVEN: 5000,
        MASTER: true,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/turkey_master_raw.png'
    },
    {
        ID: 27,
        NAME: "Waterfowl Master Badge",
        DESCRIPTION: "250 waterfowl killed",
        EXPGIVEN: 5000,
        MASTER: true,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/waterfowl_master_raw.png'
    },
    {
        ID: 28,
        NAME: "Upland Master Badge",
        DESCRIPTION: "400 upland game killed",
        EXPGIVEN: 5000,
        MASTER: true,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/upland_master_raw.png'
    },
    {
        ID: 29,
        NAME: "Small Game Master Badge",
        DESCRIPTION: "300 varmint or small game killed",
        EXPGIVEN: 5000,
        MASTER: true,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/varmint_master_raw.png'
    },
    {
        ID: 30,
        NAME: "Fishing Master Badge",
        DESCRIPTION: "1000 fish caught",
        EXPGIVEN: 5000,
        MASTER: true,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/fishing_master_raw.png'
    },
    {
        ID: 31,
        NAME: "The Outdoorsman",
        DESCRIPTION: "Have all of the Master Badges",
        EXPGIVEN: 10000,
        MASTER: true,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/outdoorsman.png'
    },
    {
        ID: 32,
        NAME: "Maybe Next Time",
        DESCRIPTION: "Less than a 15% shooting percentage (at least 20 shots) on a hunt",
        EXPGIVEN: 50,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/maybe_next_time.png'
    },
    // {
    //     ID: 33,
    //     NAME: "The Scout",
    //     DESCRIPTION: "Go on 15 scouts",
    //     EXPGIVEN: 100,
    //     MASTER: false,
    //     HAS: false
    // },
    {
        ID: 34,
        NAME: "Feeling It",
        DESCRIPTION: "60% or better shooting percentage (at least 20 shots) on a hunt",
        EXPGIVEN: 200,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/feeling_it.png'
    },
    {
        ID: 35,
        NAME: "Back to the Range",
        DESCRIPTION: "5 or more misses in a season (deer or turkey)",
        EXPGIVEN: 50,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/back_to_the_range.png'
    },
    {
        ID: 36,
        NAME: "Wall of Fame",
        DESCRIPTION: "10 or more animals in the Trophy Room",
        EXPGIVEN: 200,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/wall_of_fame.png'
    },
    {
        ID: 37,
        NAME: "Great Season",
        DESCRIPTION: "5000 points in a season",
        EXPGIVEN: 500,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/great_season.png'
    },
    {
        ID: 38,
        NAME: "Always Next Year",
        DESCRIPTION: "Less than 500 points in a season",
        EXPGIVEN: 50,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/always_next_year.png'
    },
    {
        ID: 39,
        NAME: "The Crippler",
        DESCRIPTION: "More than 3 cripples in a season (big game) or 30 birds",
        EXPGIVEN: 50,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/crippler.png'
    },
    {
        ID: 40,
        NAME: "The Robinhood",
        DESCRIPTION: "Kill a big game animal with a bow",
        EXPGIVEN: 100,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/robinhood.png'
    },
    {
        ID: 41,
        NAME: "The Cowboy",
        DESCRIPTION: "Kill a big game animal with a pistol",
        EXPGIVEN: 100,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/cowboy.png'
    },
    {
        ID: 42,
        NAME: "The Musketeer",
        DESCRIPTION: "Kill a big game animal with a muzzleloader",
        EXPGIVEN: 100,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/musketeer.png'
    },
    {
        ID: 43,
        NAME: "Target Species",
        DESCRIPTION: "Catch 12 different species of fish",
        EXPGIVEN: 500,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/target_species.png'
    },
    {
        ID: 44,
        NAME: "Banded",
        DESCRIPTION: "Retrieve a band from a bird",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/banded.png'
    },
    {
        ID: 45,
        NAME: "Werewolf",
        DESCRIPTION: "Kill a big game animal on a full moon",
        EXPGIVEN: 500,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/werewolf.png'
    },
    {
        ID: 46,
        NAME: "Fishcicle",
        DESCRIPTION: "Log a fishing trip in less than 32 degree temperatures",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/fishcicle.png'
    },
    {
        ID: 47,
        NAME: "Target Rich",
        DESCRIPTION: "15 or more target animals in range in one trip",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/target_rich.png'
    },
    {
        ID: 48,
        NAME: "Dedicated",
        DESCRIPTION: "Be in the field for 5 or more hours in one trip",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/dedicated.png'
    },
    {
        ID: 49,
        NAME: "Igloo",
        DESCRIPTION: "Log a trip with at least 10 inches of snow on the ground",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/igloo.png'
    },
    {
        ID: 50,
        NAME: "Tornado",
        DESCRIPTION: "Log a trip with at least 20 mph wind speed",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/tornado.png'
    },
    {
        ID: 51,
        NAME: "Scorched",
        DESCRIPTION: "Log a trip in at least 100 degree weather",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/scorched.png'
    },
    {
        ID: 52,
        NAME: "Fluent",
        DESCRIPTION: "Log a trip with a max call receptiveness (3)",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/fluent.png'
    },
    {
        ID: 53,
        NAME: "Armory",
        DESCRIPTION: "Log a trip with 3 or more different configurations",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/armory.png'
    },
    {
        ID: 54,
        NAME: "Killer Spread",
        DESCRIPTION: "Log a trip with 120 or more decoys",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/killer_spread.png'
    },
    {
        ID: 55,
        NAME: "Burnin' Powder",
        DESCRIPTION: "Log a trip with 100 or more shots taken",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/burnin_powder.png'
    },
    {
        ID: 56,
        NAME: "A+ Student",
        DESCRIPTION: "See 3 or more trophies in one trip",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/a_plus_student.png'
    },
    {
        ID: 57,
        NAME: "Man's Best Friend",
        DESCRIPTION: "Log a trip with a dog",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/mans_best_friend.png'
    },
    {
        ID: 58,
        NAME: "Ötzi",
        DESCRIPTION: "Log a waterfowl or upland hunt with ice",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/otzi.png'
    },
    {
        ID: 59,
        NAME: "Bonus Duck",
        DESCRIPTION: "Log a waterfowl hunt with 5 or more different species killed",
        EXPGIVEN: 500,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/bonus_duck.png'
    },
    {
        ID: 60,
        NAME: "Flush",
        DESCRIPTION: "Log an upland hunt with 5 or more coveys seen",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/flush.png'
    },
    {
        ID: 61,
        NAME: "Gigged",
        DESCRIPTION: "Log a varmint trip with a frog kill game stat",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/gigged.png'
    },
    {
        ID: 62,
        NAME: "If at First You Don't Succeed",
        DESCRIPTION: "Log a varmint trip with 5 or more stands",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/first_succeed.png'
    },
    {
        ID: 63,
        NAME: "Flowin'",
        DESCRIPTION: "Log a fishing trip with a 'Fast' water current",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/flowin.png'
    },
    {
        ID: 64,
        NAME: "20,000 Leagues",
        DESCRIPTION: "Log a fishing trip with a '50+' Fishing Depth",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/2k_leagues.png'
    },
    {
        ID: 65,
        NAME: "Fishin' in the Dark",
        DESCRIPTION: "Log a night fishing trip",
        EXPGIVEN: 250,
        MASTER: false,
        HAS: false,
        IMAGE: 'https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/fishing_in_the_dark.png'
    }
]