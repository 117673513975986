import { ToastController } from '@ionic/angular';

export class ToastFunctions{

    constructor(private toastCtrl: ToastController){}

    async presentErrorToast(message?) {
        const toast = await this.toastCtrl.create({
          message: 'Error! Please try again later or contact info@statoutdoors.com',
          duration: 2000,
          color: 'danger',
          header: message
        });
        toast.present();
      }

    async presentToast(message: string){
      const toast = await this.toastCtrl.create({
        position: 'top',
        message: message,
        duration: 3000
      });
      toast.present();
    }
    

}