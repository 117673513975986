<ion-app>
  <!-- <ion-split-pane contentId="menu-content" [disabled]="loginDisable"> -->
    <ion-menu class="menu" side="start" type="overlay" contentId="menu-content" swipeGesture="false">

      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>Menu</ion-title>          
          <ion-note color="light" slot="end" style="font-size: 10px;">v{{version}}</ion-note>
        </ion-toolbar>
      </ion-header>

      <ion-content class="noBackground">
        <!-- <ion-item button (click)="closeMenu()" [routerLink]="['/profile']">
          <ion-avatar slot="start">
            <img *ngIf="user?.hasProfilePicture" src="{{api}}/images/image/{{user?.profilePicture}}"/>
            <img *ngIf="!user?.hasProfilePicture" src="https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/stat_default_profile_pic.png">
          </ion-avatar>
          <ion-label>{{user?.username}} </ion-label>
        </ion-item> -->
       <ion-item button (click)="closeMenu()" [routerLink]="['/spots']">
          <ion-icon slot="start" name="map"></ion-icon>
          <ion-label>Spots</ion-label>
        </ion-item>
        <ion-item button (click)="closeMenu()" [routerLink]="['/equipment']">
          <ion-icon slot="start" src="/assets/img/pack.svg"></ion-icon>
          <ion-label>Equipment</ion-label>
        </ion-item>
        <ion-item button (click)="closeMenu()" [routerLink]="['/weather-pattern-home']">
          <ion-icon slot="start" name="partly-sunny"></ion-icon>
          <ion-label>Weather Patterns <ion-badge style="font-size: 8px;" color="primary">New</ion-badge></ion-label>
        </ion-item>
        <ion-item button (click)="closeMenu()" [routerLink]="['/social']">
          <ion-icon name="share-social" slot="start"></ion-icon>          
          <ion-label>Social</ion-label>
        </ion-item>
        <ion-item button (click)="closeMenu()" [routerLink]="['/insights']">
          <ion-icon name="bar-chart" slot="start"></ion-icon>
          <ion-label>Insights <ion-badge style="font-size: 8px;" color="primary">NEW</ion-badge>
          </ion-label>
        </ion-item>
        <ion-item button (click)="closeMenu()" [routerLink]="['/profile']">
          <ion-icon name="person" slot="start"></ion-icon>          
          <ion-label>Profile </ion-label>
        </ion-item> 
      </ion-content>
      
      <ion-footer>
        <ion-toolbar>
          <ion-item button (click)="closeMenu()" [routerLink]="['/about']">
            <ion-icon slot="start" name="information-circle"></ion-icon>
            <ion-label>About</ion-label>
          </ion-item>
          <ion-item lines="none" button (click)="logout()">
            <ion-icon slot="start" name="exit"></ion-icon>
            <ion-label>LogOut</ion-label>
          </ion-item>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="menu-content" [swipeGesture]="false"></ion-router-outlet>
  <!-- </ion-split-pane> -->
</ion-app>