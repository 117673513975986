import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { from as observableFrom } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserModel } from '../models/userModel';
import { Apollo } from 'apollo-angular';
import { AddSocialPost, AddUser, DeleteUser } from '../apollo/mutations';
import { API_URL } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { badges } from '../constants/badges';
import * as moment from 'moment';
import { ToastFunctions } from '../utilities/toastFunctions';

const AUTH_DATA_KEY = 'auth';

export class AuthData {
    token: string;
    username: string;
    userId: number;
    refreshToken: string;
}

@Injectable()
export class AuthService {

    constructor(
        private apollo: Apollo,
        private http: HttpClient,
        private storage: StorageService,
        private alertCtrl: AlertController,
        private loadingCtrl: LoadingController,
        private toast: ToastController
    ) { }

    toastFunctions = new ToastFunctions(this.toast);


    // Login with a stored auth token
    public refreshToken(authToken): Observable<any> {
        const tokenData = new URLSearchParams();
        tokenData.set('refreshToken', authToken.refreshToken);

        return this.clearAuthData().pipe(
          concatMap(resut => {
            return this.http.post(
              API_URL + '/auth/refreshToken',
              tokenData.toString(),
              {
                  headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
              }
            );
          })).pipe(concatMap(async (data: any) => {
            console.log('Refresh token result');
            console.dir(data);
            if(data.newSeason?.isNewSeason) {
               this.handleNewSeason(data.newSeason)
            }
            return await this.storage.set(AUTH_DATA_KEY, data.auth);
        }));
    }

    // Login with a username and password
    public login(credentials): Observable<any> {
        const loginData = new URLSearchParams();
        loginData.set('username', credentials.username);
        loginData.set('password', credentials.password);

        return this.http.post(
            API_URL + '/auth/login',
            loginData.toString(),
            {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
            }
        ).pipe(concatMap(async (data: any) => {
            if(data.newSeason?.isNewSeason) {
                this.handleNewSeason(data.newSeason)
             }
            return await this.storage.set(AUTH_DATA_KEY, data.auth);
        }));
    }

    public getCurrentAuthData(): Observable<any> {
        return observableFrom(this.storage.get(AUTH_DATA_KEY));
    }

    private clearAuthData(): Observable<any> {
      return observableFrom(this.storage.remove(AUTH_DATA_KEY));
    }

    public validateAuthData(authData): Observable<AuthData> {
        const tokenData = new URLSearchParams();
        tokenData.set('token', authData.token);

        return this.http.post(
            API_URL + '/auth/validate',
            tokenData.toString(),
            {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
            }).pipe(
                map(valid => authData)
            );
    }

    public validateEmailVerificationToken(token): Observable<any> {
        return this.http.get(
            API_URL + '/register/verify/' + token,
        );
    }

    public sendForgotPasswordEmailToken(emailAddress): Observable<any> {
        const emailData = new URLSearchParams();
        emailData.set('email', emailAddress)
        return this.http.post(
            API_URL + '/password-reset/send-token',
            emailData.toString(),
            {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
            }
        )
    }

    public validateForgotPasswordEmailToken(token): Observable<any> {
        return this.http.get(
            API_URL + '/password-reset/verify/' + token
        )
    }

    public resetPassword(token, password): Observable<any> {
        const passwordData = new URLSearchParams()
        passwordData.set('token', token);
        passwordData.set('password', password);

        return this.http.post(
            API_URL + '/password-reset/reset',
            passwordData.toString(),
            {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
            }
        )
    }

    public register(userModel: UserModel): Observable<any> {
        console.log(userModel)
        // return
        let profilePicture = null;

        return this.apollo.mutate<any>({
            mutation: AddUser,
            variables: {
                username: userModel.username,
                firstName: userModel.firstName,
                lastName: userModel.lastName,
                email: userModel.email,
                zip: userModel.zip,
                password: userModel.password,
                profilePictureName: profilePicture,
                dashboards: userModel.dashboards
            }
        });
    }

    public deleteAccount(): Observable<any> {
        return this.apollo.mutate<any>({
            mutation: DeleteUser,
        })
    }

    public logout(): Observable<void> {
        return observableFrom(this.storage.remove(AUTH_DATA_KEY));
    }

    private async  handleNewSeason(newSeason: any) {
        const alert = await this.alertCtrl.create({
            header: "NEW SEASON",
            subHeader: `Good Luck this season!`,
            message: `<img src="https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/new_season.png" style="border-radius: 2px">`,
            buttons: [
                {
                    text: 'Ok',
                    handler: async () => {
                        for(const badge of newSeason?.badgesEarned) {
                            const badgeEarned = badges.find(b => b.ID === badge);
                            const badgeAlert = await this.alertCtrl.create({
                                header: `Badge Earned - ${badgeEarned.NAME}`,
                                subHeader: `${badgeEarned.DESCRIPTION} - ${badgeEarned.EXPGIVEN} exp`,
                                message: `<img src="${badgeEarned.IMAGE}" style="border-radius: 2px">`,
                                buttons: [
                                    {
                                        text: 'Share',
                                        handler: async () => {
                                          const loading = await this.loadingCtrl.create({
                                            message: 'Sharing to social page...',
                                          });
                                          await loading.present();
                            
                                          this.apollo.mutate({
                                            mutation: AddSocialPost,
                                            variables: {
                                              newPost: `Earned the ${badgeEarned.NAME} badge!`,
                                              badgeEarned: badgeEarned.ID,
                                              createdAt: moment().format(),
                                            },
                                          }).subscribe(result => {
                                            console.log(result);
                                            loading.dismiss();
                                            this.toastFunctions.presentToast('Shared to social page!')
                                          }, err => {
                                            console.log(err)
                                            loading.dismiss();
                                            this.toastFunctions.presentErrorToast();
                                          })
                                        }
                                      },
                                    {
                                        text: 'Ok',
                                    }
                                ],
                            });
                            await badgeAlert.present();
                            await badgeAlert.onDidDismiss();
                        }
                        if(newSeason?.level?.levelUp) {
                            const levelUpAlert = await this.alertCtrl.create({
                                header: 'LEVELED UP!',
                                subHeader: `You have leveled up to level ${newSeason?.level?.level}`,
                                message: `<img src="https://stat-outdoors-bucket.s3.us-east-2.amazonaws.com/levels/level_${newSeason?.level?.level}.png" style="border-radius: 2px">`,
                                buttons: [
                                    {
                                        text: 'Share',
                                        handler: async () => {
                                          const loading = await this.loadingCtrl.create({
                                            message: 'Sharing to social page...',
                                          });
                                          await loading.present();
                            
                                          this.apollo.mutate({
                                            mutation: AddSocialPost,
                                            variables: {
                                              newPost: `Leveled Up!`,
                                              levelEarned: newSeason?.level?.level,
                                              createdAt: moment().format(),
                                            },
                                          }).subscribe(result => {
                                            // console.log(result);
                                            loading.dismiss();
                                            this.toastFunctions.presentToast('Shared to social page!')
                                          }, err => {
                                            console.log(err)
                                            loading.dismiss();
                                            this.toastFunctions.presentErrorToast();
                                          })
                                        }
                                      },
                                    {
                                        text: 'Ok',
                                    }
                                ],
                            });
                            await levelUpAlert.present();
                            await levelUpAlert.onDidDismiss();
                        }
                    }
                },
            ],
        });

        await alert.present();
    }
}
