import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { concatMap, map, catchError } from 'rxjs/operators';
import { AuthService } from './services/authService';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      console.log('Checking if route can be activated');
      return this.authService.getCurrentAuthData()
        .pipe(concatMap(authData => {
          return this.authService.validateAuthData(authData)
            .pipe(catchError(err => this.authService.refreshToken(authData)));
        }),
        map(authData => {
          console.log('User is logged in, route activted');
          return true;
        }),
        catchError(err => {
          console.log('User not logged in, redirecting to login page');
          this.authService.logout();
          this.router.navigate(['/login']);
          return of(false);
        }));
  }
}
